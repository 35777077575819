<template>
  <div v-if="fieldDefs != null && $root.$data.me.access.RB1">
    <b-container fluid>
      <b-row>
        <b-col>
          
          <h1 v-if="release">Release concept<span v-if="release != null">: {{ release.Title }} - {{ release.Attribution }}</span></h1>
          <h1 v-if="release == false">Release concept not found, or not allowed to access</h1>

          <a class="knowledgebase" href="https://support.the-source.eu/kb/c1/root-folder.aspx" target="_blank">Supportportal</a>
        </b-col>
      </b-row>
      <b-row v-if="release">
        <b-col>
          <ul class="wizard-steps" v-if="$root.$data.me.IsSuperUser || $root.$data.me.EMail == 'ronald@trcmusic.com'">
            <li v-for="(item, index) in steps" 
                :key="index" v-bind:class="{ active: step == index }"
                @click="setStep(index)"
                style="cursor: pointer"
             >
              {{ item.title }}
            </li>
          </ul>

          <ul class="wizard-steps" v-if="!($root.$data.me.IsSuperUser || $root.$data.me.EMail == 'ronald@trcmusic.com')">
            <li v-for="(item, index) in steps" 
                :key="index" v-bind:class="{ active: step == index }"
             >
              {{ item.title }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>

    <component 
      :is="currentStepComponent" 
      v-if="release != null && releaseSettings != null" 
      :release="release"
      :fieldDefs="fieldDefs"
      :settings="releaseSettings"
      :createdRelease="createdRelease"
      :validationErrors="validationErrors"
      v-on:proceed="onProceed"
      v-on:create="onCreate" />

    <div v-if="creating">Creating release<b-spinner></b-spinner></div>

    <b-container fluid class="mt-5" v-if="$root.$data.me.IsSuperUser || $root.$data.me.EMail == 'ronald@trcmusic.com'">
      <b-row>
        <b-col>
          <pre style="color: white;">{{ release|jsonPretty }}</pre>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'
  import { generateAttribution } from '@/functions'

  export default {
    name: 'ReleaseConcept',
    components: {
      AssetUpload: () => import('@/components/release-concept-steps/AssetUpload'),
      Tracks: () => import('@/components/release-concept-steps/Tracks'),
      Release: () => import('@/components/release-concept-steps/Release'),
      Outlets: () => import('@/components/release-concept-steps/Outlets'),
      Confirm: () => import('@/components/release-concept-steps/Confirm'),
      Finished: () => import('@/components/release-concept-steps/Finished'),
    },
    mixins: [
      ReleaseConceptHelper
    ],
    props: {
      id: String,
    },
    data () {
      return {
        validationErrors: [],
        counterInterval: null,
        release: null,
        creating: false,
        createdRelease: null,
        step: null,
        fieldDefs: this.$root.$data.fieldDefs,
        releaseSettings: null,
        steps: [
          { component: 'AssetUpload', title: 'Asset upload' },
          { component: 'Tracks', title: 'Tracks' },
          { component: 'Release', title: 'Release' },
          { component: 'Outlets', title: 'Outlets' },
          { component: 'Confirm', title: 'Confirm' },
          { component: 'Finished', title: 'Created' },
        ]
      }
    },
    methods: {
      save () {
        console.log('Saving release')
        this.$http.put('release-concepts/' + this.id, { Json: JSON.stringify(this.release) })
          .then(() => {
            console.log('Release saved')
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getMaxCount (obj) {
        let vals = Object.values(obj)
        vals = vals.sort((a,b) => { return b.Count - a.Count })
        let val = vals[0]
        if (Object.keys(val).includes('Value'))
          return val.Value
        return val
      },
      validate () {
        console.log('Validate')
        let release = this.release

        // Collect track values
        let trackTopLevel = {}
        let trackRecordingVersion = {}
        let trackPriceTier = {}
        let trackGenre = {}
        let trackOccasions = []
        let trackInfluences = []
        let trackContributors = []

        release.Tracks.forEach(track => {
          if (track.UseExisting) {
            track = track.ExistingData
          }
          
          let key = this.getTag(track, 'TopLevel')
          if (!trackTopLevel[key])
            trackTopLevel[key] = {Value: key, Count: 0}
          trackTopLevel[key].Count++
          
          key = this.getTag(track, 'RecordingVersion')
          if (!trackRecordingVersion[key])
            trackRecordingVersion[key] = {Value: key, Count: 0}
          trackRecordingVersion[key].Count++

          key = this.getTag(track, 'TrackPriceTier')
          if (!trackPriceTier[key])
            trackPriceTier[key] = {Value: key, Count: 0}
          trackPriceTier[key].Count++

          let main = this.getTag(track, 'MainGenre')
          let sub = this.getTag(track, 'SubGenre')
          key = main + sub
          if (!trackGenre[key])
            trackGenre[key] = {Count: 0, MainGenre: main, SubGenre: sub}
          trackGenre[key].Count++

          this.getTag(track, 'Occasions', true).forEach(t => {
            if (!trackOccasions.includes(t)) trackOccasions.push(t)
          })

          this.getTag(track, 'OtherInfluences', true).forEach(t => {
            if (!trackInfluences.includes(t)) trackInfluences.push(t)
          })

          // Save track contributors. Only primary if multiple tracks
          if (track.Contributors && (release.Tracks.length == 1 || !release.Contributors || release.Contributors.length == 0)) {
            track.Contributors.forEach(c => {
              if (release.Tracks.length == 1 || c.Type == 'Primary') {
                if (!trackContributors.some(c2 => JSON.stringify(c) == JSON.stringify(c2))) {
                  trackContributors.push(JSON.parse(JSON.stringify(c)))
                }
              }
            })
          }
        })

        trackTopLevel = this.getMaxCount(trackTopLevel)
        trackRecordingVersion = this.getMaxCount(trackRecordingVersion)
        trackGenre = this.getMaxCount(trackGenre)
        trackPriceTier = this.getMaxCount(trackPriceTier)
        let trackMainGenre = trackGenre.MainGenre
        let trackSubGenre = trackGenre.SubGenre

        if (release.Tracks.length == 1) {
          // Copy data from track to album
          this.$set(release, 'Contributors', trackContributors)
        
          // Force values to track values
          this.setAttribute(release, 'ReleaseType', 'SingleTrack')
          
          // Decide source for data
          let track = release.Tracks[0]
          if (track.UseExisting) {
            track = release.Tracks[0].ExistingData
          }

          release.TitleLanguage = track.TitleLanguage
          release.Title = track.Title
          release.VersionTitle = track.VersionTitle
          release.Attribution = track.Attribution
          release.Attribution = generateAttribution(release, true)
          release.PLine = track.PLine
          release.CLine = track.CLine

          if (this.getTag(release, 'TopLevel') != trackTopLevel)
            this.setTag(release, 'TopLevel', trackTopLevel)

          if (this.getTag(release, 'RecordingVersion') != trackRecordingVersion)
            this.setTag(release, 'RecordingVersion', trackRecordingVersion)

          if (this.getTag(release, 'TrackPriceTier') != trackPriceTier)
            this.setTag(release, 'TrackPriceTier', trackPriceTier)

          if (this.getTag(release, 'MainGenre') != trackMainGenre)
            this.setTag(release, 'MainGenre', trackMainGenre)

          if (this.getTag(release, 'SubGenre') != trackSubGenre)
            this.setTag(release, 'SubGenre', trackSubGenre)



        } else {
          if (!this.getTag(release, 'TopLevel'))
            this.setTag(release, 'TopLevel', trackTopLevel)

          if (!this.getTag(release, 'RecordingVersion'))
            this.setTag(release, 'RecordingVersion', trackRecordingVersion)

          if (!this.getTag(release, 'TrackPriceTier'))
            this.setTag(release, 'TrackPriceTier', trackPriceTier)

          if (!this.getTag(release, 'MainGenre')) {
            this.setTag(release, 'MainGenre', trackMainGenre)
            this.setTag(release, 'SubGenre', trackSubGenre)
          }
        }

        if (trackOccasions.length > 0 && this.getTag(release, 'Occasions', true).length == 0)
          this.setTag(release, 'Occasions', trackOccasions)

        if (trackInfluences.length > 0 && !this.getTag(release, 'OtherInfluences', true))
          this.setTag(release, 'OtherInfluences', trackInfluences)


        // IDC Track & Productclass
        let map = {
          SingleTrack: {IDCTrackFormat: 'Track', ProductClass: 'CDS', AlbumPriceTier: 'Single'},
          Single: {IDCTrackFormat: 'Single', ProductClass: 'CDS', AlbumPriceTier: 'Single'},
          MiniEP: {IDCTrackFormat: 'Single', ProductClass: 'CDM', AlbumPriceTier: 'Mini-EP'},
          Album: {IDCTrackFormat: 'Album', ProductClass: 'Album', AlbumPriceTier: null},
          Compilation: {IDCTrackFormat: 'Compilation', ProductClass: 'Album', AlbumPriceTier: null},
          EP: {IDCTrackFormat: 'Single', ProductClass: 'CDM', AlbumPriceTier: 'EP'},
        }

        // Release Priority
        if (this.getAttribute(release, 'ReleasePriority') == 'High') {
          this.$set(this.release, 'DeliveryStatus', '51')
        } else if (this.release.DeliveryStatus == '51') {
          this.$set(this.release, 'DeliveryStatus', '40')
        }

        // Set ReleaseType based options
        let releaseType = this.getAttribute(release, 'ReleaseType')
        if (releaseType) {
          let idcPcApt = map[releaseType]
          if (this.getTag(release, 'IDCTrackFormat') != idcPcApt.IDCTrackFormat)
            this.setTag(release, 'IDCTrackFormat', idcPcApt.IDCTrackFormat) 
            
          if (this.getTag(release, 'ProductClass') != idcPcApt.ProductClass)
            this.setTag(release, 'ProductClass', idcPcApt.ProductClass) 
            
          if (idcPcApt.AlbumPriceTier && this.getTag(release, 'AlbumPriceTier') != idcPcApt.AlbumPriceTier)
            this.setTag(release, 'AlbumPriceTier', idcPcApt.AlbumPriceTier) 
        }

        // Standard tags
        if (this.getTag(release, 'Category') != 'Audio')
          this.setTag(release, 'Category', 'Audio')

        if (this.getTag(release, 'DeliveryClass') != 'Generic_THSO')
          this.setTag(release, 'DeliveryClass', 'Generic_THSO')
        
        release.Tracks = release.Tracks.map(track => {

          // Standard tag
          if (this.getTag(track, 'Category') != 'Audio')
            this.setTag(track, 'Category', 'Audio')

          // Copy Album values to track
          let yt_match_policy = this.getAttribute(release, 'YT_MATCH_POLICY')
          if (yt_match_policy) {
            this.setAttribute(track, 'YT_MATCH_POLICY', yt_match_policy)
          }

          let omitForContentId = this.getAttribute(release, 'OmitForContentID')
          if (omitForContentId) {
            this.setAttribute(track, 'OmitForContentID', omitForContentId)
          }

          let rightsClaim = this.getAttribute(release, 'RightsClaimPolicyType')
          if (rightsClaim) {
            this.setAttribute(track, 'RightsClaimPolicyType', rightsClaim)
          }

          // Fix issues
          if (track.ISRC)
            track.ISRC = track.ISRC.replace(/[^a-z0-9]/gi, '').toUpperCase()


          return track
        })

        // Fix some issues:
        if (typeof release.CatalogNr === 'string' || release.CatalogNr instanceof String)
          release.CatalogNr = release.CatalogNr.toUpperCase().replace(' ', '')

        // Update release
        this.$set(this, 'release', release)

        // Validate
        let errors = []

        this.$set(this, 'validationErrors', errors)
        return this.validationErrors.length == 0
      },
      setStep (newStep) {
        this.save()
        if (this.step > 0 && !this.validate()) return

        this.step = newStep
        this.$root.scroll()
      },
      onProceed () {
        this.setStep(this.step+1)
      },
      onCreate (e) {
        if (this.creating) return

        this.creating = true

        this.$http.put('release-concepts/' + this.id, { 
            Json: JSON.stringify(this.release) ,
            Status: 'toCreate',
            NoCommit: e == 'nocommit'
          }).then((response) => {
            this.$set(this, 'createdRelease', response.data)
            this.creating = false
            if (!this.$root.$data.me.IsSuperUser) {
              this.step += 1
            }
          })
          .catch(() => {
            this.creating = false
            this.step += 1
          })
      },
    },
    computed: {
      currentStepComponent () {
        if (this.step == null) return ''
        return this.steps[this.step].component
      }
    },
    mounted () {
      this.$http.get('release-concepts/' + this.id)
        .then(response => {
          if (response.data == false) {
            this.release = false
            return
          }
          this.$set(this, 'release', JSON.parse(response.data.Json))
          this.releaseSettings = response.data.Settings

          if (this.release.Tracks && this.release.Tracks.length > 0 &&
              this.release.Artwork && this.release.Artwork.length > 0) {
            this.step = 1
            return
          } else {
            this.step = 0
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    created: function() {
      /*
      this.counterInterval = setInterval(function() {
        if (this.release && this.release.Attributes) {
          if (!this.release.Attributes.every(attr => { return attr.Type })) {
            alert('Json got corrupted')
            clearInterval(this.counterInterval)
          }
        }
      }.bind(this), 1000); */
    },
  }
</script>

<style lang="scss" scoped>
  a.knowledgebase {
    position: absolute;
    right: 20px;
    top: 0;
  }

  ul.wizard-steps {
    margin-left: 0;
    padding: 0;

    li {
      &::before {
        font-weight: normal;  
        content: '>> ';
      }
      &.active {
        font-weight: bold;
      }
      margin-right: 0.5em;
      display: inline-block;
      list-style-type: none;
    }
  }
</style>
